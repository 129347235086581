import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import uploadImage from "../../../../services/image-uploader";
import DealerSalespersonGroupInput from "@/components/DealerSalespersonGroupInput";
import Roles from "./Roles/Roles.vue";

import getAxios from "../../../../services/axios-get";
import putAxios from "../../../../services/axios-put";
import deleteAxios from "../../../../services/axios-delete";
import postAxios from "../../../../services/axios-post";
import patchAxios from "../../../../services/axios-patch";
import moment from "moment";
Vue.use(VueAxios, axios);

export default {
  components: {
    DealerSalespersonGroupInput,
    Roles,
  },
  data() {
    return {
      tab: null,
      dealerTabs: ["Dealer Details", "Roles"],
      editDialog: false,
      selectedCarBrand: null,
      isLoading: false,
      dealerCarBrandsList: [],
      benched: 0,
      entries: [],
      search: null,
      dealerSearch: "",
      dealerStatus: "",
      imgFile: null,
      imgUrl: null,
      primaryListingBannerDisplay: null,
      secondaryListingBannerDisplay: null,
      tertiaryListingBannerDisplay: null,
      subscriptionEndDateModel: false,
      subscriptionStartDateModel: false,
      pageCount: 0,
      associatedCarBrandsDialog: false,
      shortcutEditDialog: false,
      editNameDialog: false,
      deleteDialog: false,
      editPhotoDialog: false,
      editCrshortcutEditDialog: false,
      editDescriptionDialog: false,
      assignAccountManagerDialog: false,
      subscriptionDialog: false,
      snackbar: false,
      snackMessage: "",
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Logo", value: "logoPath" },
        { text: "Phone", value: "mobilePhone" },
        { text: "Address", value: "address" },
        { text: "SGCM listing", value: "numOfSgcmListing" },
        { text: "UEN", value: "uenNum" },
        { text: "Status", value: "status" },
        { text: "Manager", value: "accountManagerName" },
        { text: "Platforms", value: "platforms", sortable: false },
        // { text: "Type", value: "dealerAssociatedTypes" },
        { text: "Subscription", value: "dealerSubscription", sortable: false },
        { text: "Credit Balance", value: "creditBalance" },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
      dealerList: [],
      editDealer: {
        dealerId: null,
        name: null,
        mobilePhone: null,
        address: null,
        numOfSgcmListing: 0,
        uenNum: null,
        status: null,
        creditBalance: 0,
        logoName: null,
        logoPath: null,
        description: null,
        hideCarFlag: null,
        authorizedFlag: null,
        primaryListingBannerPath: null,
        secondaryListingBannerPath: null,
        tertiaryListingBannerPath: null,
        facebookUrl: null,
        websiteUrl: null,
        dealerAssociatedTypes: [],
        locality: null,
      },
      dealerStatusList: [
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "APPROVED", value: "APPROVED" },
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "REJECTED", value: "REJECTED" },
      ],
      platformList: [],
      dealerTypeList: [],
      accountManagerList: [],
      assignPlatformDialog: false,
      assignDealerTypeDialog: false,
      assignPlatformData: {
        platformIds: [],
        name: "",
        dealerId: "",
      },
      dealerSubscriptionData: {
        dealerId: null,
        dealerSubscriptionId: null,
        planStartDate: null,
        planEndDate: null,
      },
      selectedDealerSubscriptionId: null,
      assignDealerTypeData: {
        dealerTypeId: [],
        name: "",
        dealerId: "",
      },
      assignAccountManagerData: {
        accountManagerId: "",
        dealerId: "",
      },
      delPiAndDealer: {
        id: "",
        name: "",
      },
      subscriptions: [],
      roles: [],
      minDate: null,
      maxDate: null,
      sortValue: null,
      sortBy: null,
      sortDesc: null,
      selectedDealer: null,
    };
  },
  computed: {
    carBrandSearchEntries() {
      const currectDealerCarBrandIds = this.dealerCarBrandsList.map(
        x => x.carBrandId
      );
      var d = this.entries
        .filter(x => currectDealerCarBrandIds.indexOf(x.id) < 0)
        .map(x => ({
          text: x.name,
          value: x.id,
          disabled: false,
          logoPath: x.logoPath,
          name: x.name,
          id: x.id,
        }));
      return d;
    },
  },
  watch: {
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      console.log("Loading here: " + val);

      // Lazily load input items
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carBrand?searchContent=${val}`
      )
        .then(res => {
          this.entries = res.data.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.fetchDealerList();
    this.fetchPlatforms();
    this.fetchSubscription();
    this.fetchDealerTypes();
    this.fetchAccountManagers();
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName;
      columnName =
        sortBy[0] === "accountManagerName" ? "accountManager.name" : sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = null;
      }
      this.fetchDealerList();
    },
    selectedStartDate(date) {
      this.subscriptionStartDateModel = false;
      if (date) {
        const selectedDate = new Date(date);
        const day = selectedDate.getDate();
        const month = selectedDate.getMonth() + 1;
        const year = selectedDate.getFullYear();
        if (day === 1) {
          this.minDate = date;
        } else {
          const minMonth = month === 12 ? 1 : month + 1;
          const minYear = month === 12 ? year + 1 : year;
          const dateMin = minYear + "-" + minMonth + "-" + "1";
          this.minDate = this.convertDate(dateMin);
        }
      }
    },
    selectedEndDate(date) {
      this.subscriptionEndDateModel = false;
      if (date) {
        const selectedDate = new Date(date);
        const month = selectedDate.getMonth() + 1;
        const year = selectedDate.getFullYear();
        const dateMax = year + "-" + month + "-" + "1";
        this.maxDate = this.convertDate(dateMax);
      }
    },
    convertDate(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD");
      return parsedDate;
    },
    fetchSubscription() {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-subscription`,
        this.token
      ).then(res => {
        if (res.data) {
          this.subscriptions = res.data.items;
        }
      });
    },
    resetdealerSubscription() {
      this.dealerSubscriptionData.dealerId = null;
      this.dealerSubscriptionData.dealerSubscriptionId = null;
      this.dealerSubscriptionData.planStartDate = null;
      this.dealerSubscriptionData.planEndDate = null;
      this.selectedDealerSubscriptionId = null;
    },
    assignDealerSubscription(item) {
      this.resetdealerSubscription();
      this.dealerSubscriptionData.dealerId = item.id;
      const filter = encodeURI(`[["dealer.id", "eq", "${item.id}"]]`);
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-vs-dealer-subscription?filter=${filter}`
      ).then(res => {
        if (res.data) {
          const dealerSubscriptionData = res.data.items[0];
          this.dealerSubscriptionData.dealerSubscriptionId =
            dealerSubscriptionData.dealerSubscription.id;
          this.dealerSubscriptionData.planStartDate = dealerSubscriptionData.planStartDate
            ? this.convertDate(dealerSubscriptionData.planStartDate)
            : null;
          this.dealerSubscriptionData.planEndDate = dealerSubscriptionData.planEndDate
            ? this.convertDate(dealerSubscriptionData.planEndDate)
            : null;
          this.selectedDealerSubscriptionId = dealerSubscriptionData.id;
          this.selectedEndDate(this.dealerSubscriptionData.planEndDate);
          this.selectedStartDate(this.dealerSubscriptionData.planStartDate);
        }
      });
      this.subscriptionDialog = true;
    },
    doAssignSubscription() {
      if (!this.selectedDealerSubscriptionId && this.$refs.form.validate()) {
        try {
          postAxios(
            `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-vs-dealer-subscription`,
            this.dealerSubscriptionData,
            this.token
          )
            .then(res => {
              if (res.data) {
                this.fetchDealerList();
                this.snackbar = true;
                this.snackMessage = "Subscription added Successfully!";
                // this.showAlert("success", "New Subscription Added!");
              }
            })
            .catch(err => {
              console.error(err);
              this.snackbar = true;
              this.snackMessage = "Error!";
            })
            .finally(() => {
              this.subscriptionDialog = false;
            });
        } catch (e) {
          console.error(e);
        }
      } else if (
        this.selectedDealerSubscriptionId &&
        this.$refs.form.validate()
      ) {
        try {
          this.dealerSubscriptionData.id = this.selectedDealerSubscriptionId;
          patchAxios(
            `${process.env.VUE_APP_SERVER_URL}/api/v2/dealer-vs-dealer-subscription/${this.selectedDealerSubscriptionId}`,
            this.dealerSubscriptionData,
            this.token
          )
            .then(res => {
              if (res.data) {
                this.fetchDealerList();
                this.snackbar = true;
                this.snackMessage = "Subscription updated Successfully!";
              }
            })
            .catch(err => {
              console.error(err);
              this.snackbar = true;
              this.snackMessage = "Error!";
            })
            .finally(() => {
              this.subscriptionDialog = false;
            });
        } catch (e) {
          console.error(e);
        }
      }
    },
    convertDealerAssociatedTypeShortform(associatedType) {
      switch (associatedType) {
        case "PARALLEL_IMPORTER":
          return "PI";
        case "SECOND_HAND_DEALER":
          return "2nd";
        case "AUTHORISED_DEALER":
          return "AD";
        default:
          break;
      }
    },
    searchDealer() {
      this.fetchDealerList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchDealerList();
    },
    editButtonClick(item) {
      this.setEditItemInfo(item);
      this.selectedDealer = item;
      this.getRoleList(item.id);
      this.editDialog = true;
    },
    getRoleList(dealer) {
      const params = {
        dealerId: dealer,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/roleSystem/rolesByDealer`,
        params
      ).then(res => {
        if (res.data) {
          this.roles = res.data.data;
        }
      });
    },
    editStatusButtonClick(item) {
      this.setEditItemInfo(item);

      this.shortcutEditDialog = true;
    },
    editCreditButtonClick(item) {
      this.setEditItemInfo(item);

      this.editCrshortcutEditDialog = true;
    },
    editPhotoButtonClick(item) {
      this.setEditItemInfo(item);
      this.editPhotoDialog = true;
    },
    editNameButtonClick(item) {
      this.setEditItemInfo(item);
      this.editNameDialog = true;
    },
    editDescriptionButtonClick(item) {
      this.setEditItemInfo(item);
      this.editDescriptionDialog = true;
    },
    setEditItemInfo(item) {
      this.editDealer.name = item.name;
      this.editDealer.status = item.status;
      this.editDealer.dealerId = item.id;
      this.editDealer.creditBalance = item.creditBalance;
      this.editDealer.description = item.description;
      this.editDealer.mobilePhone = item.mobilePhone;
      this.editDealer.address = item.address;
      this.editDealer.numOfSgcmListing = item.numOfSgcmListing;
      this.editDealer.uenNum = item.uenNum;
      this.editDealer.hideCarFlag = item.hideCarFlag;
      this.editDealer.authorizedFlag = item.authorizedFlag;
      this.primaryListingBannerDisplay = item.primaryListingBannerPath;
      this.secondaryListingBannerDisplay = item.secondaryListingBannerPath;
      this.tertiaryListingBannerDisplay = item.tertiaryListingBannerPath;
      this.editDealer.facebookUrl = item.facebookUrl;
      this.editDealer.websiteUrl = item.websiteUrl;
      this.editDealer.locality = item.locality;
      this.editDealer.dealerAssociatedTypes = item.dealerAssociatedTypes;
    },
    resetEditItemInfo() {
      this.editDealer.name = null;
      this.editDealer.status = null;
      this.editDealer.dealerId = null;
      this.editDealer.creditBalance = null;
      this.editDealer.logoPath = null;
      this.editDealer.description = null;
      this.editDealer.mobilePhone = null;
      this.editDealer.address = null;
      this.editDealer.numOfSgcmListing = null;
      this.editDealer.uenNum = null;
      this.editDealer.hideCarFlag = null;
      this.editDealer.authorizedFlag = null;
      this.editDealer.primaryListingBannerPath = null;
      this.editDealer.secondaryListingBannerPath = null;
      this.editDealer.tertiaryListingBannerPath = null;
      this.editDealer.locality = null;
      this.primaryListingBannerDisplay = null;
      this.secondaryListingBannerDisplay = null;
      this.tertiaryListingBannerDisplay = null;
      this.imgFile = null;
      this.editDealer.dealerAssociatedTypes = [];
    },
    assignPlatformClick(item) {
      this.assignPlatformData.platformIds = item.platforms.map(p => p.id);
      this.assignPlatformData.name = item.name;
      this.assignPlatformData.dealerId = item.id;
      this.assignPlatformDialog = true;
    },
    assignDealerAssociatedTypesClick(item) {
      this.assignDealerTypeData.dealerTypeId = item.dealerAssociatedTypes.map(
        p => p.id
      );
      this.assignDealerTypeData.name = item.name;
      this.assignDealerTypeData.dealerId = item.dealerId;
      this.assignDealerTypeDialog = true;
    },
    assignAccountManagerClick(item) {
      this.assignAccountManagerData.accountManagerId = item.accountManager;
      this.assignAccountManagerData.dealerId = item.id;
      this.assignAccountManagerDialog = true;
    },
    async doEditDealer() {
      const self = this;
      try {
        if (self.imgFile) {
          const imgRes = await uploadImage(self.imgFile);
          self.editDealer.logoPath = imgRes.data.data;
        }
        if (self.editDealer.primaryListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.primaryListingBannerPath
          );
          self.editDealer.primaryListingBannerPath = imgRes.data.data;
        }
        if (self.editDealer.secondaryListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.secondaryListingBannerPath
          );
          self.editDealer.secondaryListingBannerPath = imgRes.data.data;
        }
        if (self.editDealer.tertiaryListingBannerPath) {
          const imgRes = await uploadImage(
            self.editDealer.tertiaryListingBannerPath
          );
          self.editDealer.tertiaryListingBannerPath = imgRes.data.data;
        }
        self.editDealer.hideCarFlag = self.editDealer.hideCarFlag ? 1 : 0;
        self.editDealer.authorizedFlag = self.editDealer.authorizedFlag ? 1 : 0;
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          self.editDealer
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.shortcutEditDialog = false;
            self.editNameDialog = false;
            self.editDescriptionDialog = false;
            self.editCrshortcutEditDialog = false;
            self.editPhotoDialog = false;
            self.editDialog = false;
            self.fetchDealerList();
            self.resetEditItemInfo();
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    deleteButtonClick(item) {
      this.delPiAndDealer.id = item.id;
      this.delPiAndDealer.name = item.name;
      this.deleteDialog = true;
    },
    doDelPiAndDealer() {
      const self = this;
      const param = {
        dealerId: self.delPiAndDealer.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/delDealer`,
        param,
        self.token
      ).then(res => {
        if (res.data) {
          self.deleteDialog = false;
          self.fetchDealerList();
        }
      });
    },
    fetchDealerList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.dealerSearch,
        status: self.dealerStatus,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer?isAuthorized=0`,
        params
      ).then(res => {
        if (res.data) {
          self.dealerList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    fetchPlatforms() {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/platform`)
        .then(res => {
          self.platformList = res.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    fetchDealerTypes() {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/baseDataSystem/dealerType`)
        .then(res => {
          self.dealerTypeList = res.data.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    fetchAccountManagers() {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/userSystem/accountManager`)
        .then(res => {
          self.accountManagerList = res.data.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    doAssignAccountManager() {
      const self = this;
      const { dealerId, accountManagerId } = this.assignAccountManagerData;
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/assignAccountManager`,
        {
          dealerId,
          accountManagerId,
        }
      )
        .then(() => {
          self.fetchDealerList();
        })
        .catch(err => {
          console.error(err);
          self.$emit("error", err);
        })
        .finally(() => {
          self.assignAccountManagerDialog = false;
        });
    },
    doAssignPlatforms() {
      const self = this;
      const { dealerId, platformIds } = this.assignPlatformData;
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/assignDealerPlatform`,
        {
          dealerId,
          platformIds,
        }
      )
        .then(() => {
          self.fetchDealerList();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.assignPlatformDialog = false;
        });
    },

    doAssignDealerTypes() {
      const self = this;
      const { dealerId, dealerTypeId } = this.assignDealerTypeData;
      const dealerTypes = dealerTypeId;

      putAxios(`${process.env.VUE_APP_SERVER_URL}/dealer/updDealerTypes`, {
        dealerId,
        dealerTypes,
      })
        .then(() => {
          self.editDealer.dealerAssociatedTypes = self.dealerTypeList.filter(
            d => dealerTypeId.includes(d.id)
          );
          self.fetchDealerList();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.assignDealerTypeDialog = false;
        });
    },
    showDealerCarBrands(dealerId) {
      this.fetchDealerCarBrands(dealerId);
      this.associatedCarBrandsDialog = true;
    },
    fetchDealerCarBrands(dealerId) {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/dealerInfo/associatedCarBrand?dealerId=${dealerId}`
      )
        .then(res => {
          self.dealerCarBrandsList = res.data.data;
          // self.dealerCarBrandsList.forEach(d => {
          //   self.dealerCarBrandsIdContext.push(d.carBrandId);
          // });
        })
        .catch(err => {
          console.error(err);
        });
    },
    addDealerCarBrand(selectedCarBrand) {
      this.dealerCarBrandsList.push({
        carBrandId: selectedCarBrand.id,
        carBrandName: selectedCarBrand.name,
        carBrandImgUrl: selectedCarBrand.logoPath,
      });
    },
    doUpdateDealerCarBrands(dealerId, dealerCarBrandsList) {
      const self = this;
      const carBrandId = dealerCarBrandsList.map(m => m.carBrandId);

      putAxios(`${process.env.VUE_APP_SERVER_URL}/dealer/updDealerCarBrands`, {
        dealerId,
        carBrandId,
      })
        .then(() => {
          self.fetchDealerCarBrands(self.editDealer.dealerId);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.associatedCarBrandsDialog = false;
        });
    },
    deleteFromCarBrandList(selected) {
      this.dealerCarBrandsList.forEach((d, i) => {
        if (d.carBrandId == selected.carBrandId) {
          this.dealerCarBrandsList.splice(i, 1);
        }
      });
    },

    onToggleHide(item) {
      const self = this;
      try {
        const data = {
          dealerId: item.id,
          hideCarFlag: item.hideCarFlag ? 1 : 0,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchDealerList();
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
