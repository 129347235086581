import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import postAxios from "../../../../../services/axios-post";
import patchAxios from "../../../../../services/axios-patch";
import getAxios from "../../../../../services/axios-get";
import deleteAxios from "../../../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  props: {
    selectedDealer: Object,
    roles: Array,
  },
  data() {
    return {
      tab: "",
      rollTabs: ["Display", "Permissions", "Members"],
      addRoleDialog: false,
      deleteRoleDialog: false,
      editRoleDialog: false,
      snackbar: false,
      snackMessage: "",
      selectedPermission: "",
      salespersonList: [],
      selectedMember: null,
      createRole: {
        dealerId: "",
        name: "",
      },
      editRole: {
        dealerId: "",
        name: "",
        code: "",
        color: "",
        description: "",
        id: "",
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Edit" },
        { text: "Delete" },
      ],
      deleteRole: {
        id: "",
        name: "",
      },
      permissionList: [],
      permissions: [],
      permissonSearch: "",
      roleMembers: [],
    };
  },
  // mounted() {
  //   this.getRoleList();
  // },
  methods: {
    changePermission(rolePermission, value) {
      patchAxios(
        `${process.env.VUE_APP_SERVER_URL}/roleSystem/setRoleAccess?accessId=${rolePermission.accessId}&permission=${value}&roleId=${this.editRole.id}`
      )
        .then(res => {
          if (res.data) {
            this.snackbar = true;
            this.snackMessage = "Permission changed Successfully!";
            this.getPermissionList(this.editRole.id);
          }
        })
        .catch(err => {
          console.error(err);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
    searchPermission(value) {
      const listOfPermissions = [...this.permissions];
      if (value) {
        this.permissionList = listOfPermissions.filter(permission => {
          return value
            .toLowerCase()
            .split(" ")
            .every(v => permission.name.toLowerCase().includes(v));
        });
      } else {
        this.permissionList = [...this.permissions];
        return this.permissionList;
      }
    },
    getPermissionList(role) {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/roleSystem/roleAccess?roleId=${role}`
      ).then(res => {
        if (res.data) {
          const permissionLists = res.data.data;
          const adminPermission = permissionLists.find(
            permission => permission.code === "ADMIN"
          );
          if (adminPermission.hasPermission) {
            permissionLists.map(
              permission =>
                (permission.disable =
                  permission.hasPermission && permission.code != "ADMIN")
            );
          } else {
            permissionLists.map(
              permission =>
                (permission.disable =
                  permission.hasPermission &&
                  permission.code === "VIEW_CAR_LISTINGS")
            );
          }
          this.permissionList = permissionLists;
          this.permissions = permissionLists;
        }
      });
    },
    getRoleList() {
      if (this.selectedDealer.id) {
        const params = {
          dealerId: this.selectedDealer.id,
        };
        getAxios(
          `${process.env.VUE_APP_SERVER_URL}/roleSystem/rolesByDealer`,
          params
        ).then(res => {
          if (res.data) {
            this.roles = res.data.data;
          }
        });
      }
    },
    resetEditRole() {
      this.editRole.name = "";
      this.editRole.dealerId = "";
      this.editRole.code = "";
      this.editRole.color = "";
      this.editRole.description = "";
      this.editRole.id = "";
    },
    editButtonClick(item) {
      this.resetEditRole();
      this.salespersonList = [];
      this.permissionList = [];
      this.permissions = [];
      this.roleMembers = [];
      this.editRole.name = item.name ? item.name : "";
      this.editRole.dealerId = this.selectedDealer.id
        ? this.selectedDealer.id
        : "";
      this.editRole.code = item.code ? item.code : "";
      this.editRole.color = item.color ? item.color : "";
      this.editRole.description = item.description ? item.description : "";
      this.editRole.id = item.id ? item.id : "";
      this.editRoleDialog = true;
      this.getSalesPersons();
      this.getPermissionList(item.id);
      this.getRoleMembers(item.id);
    },
    getSalesPersons() {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/roleSystem/membersByDealer?dealerId=${this.selectedDealer.id}&hasRoleAssigned=false`
      )
        .then(res => {
          this.salespersonList = res.data.data.filter(
            salePerson => salePerson.salePersonType != "MASTER"
          );
        })
        .catch(err => {
          console.error(err);
        });
    },
    assignRoleToMember(member) {
      postAxios(
        `${process.env.VUE_APP_SERVER_URL}/roleSystem/addRoleSalesperson?salespersonId=${member.salespersonId}&roleId=${this.editRole.id}`
      )
        .then(res => {
          if (res.data) {
            this.snackbar = true;
            this.snackMessage = "Role added to member Successfully!";
            this.getRoleMembers(this.editRole.id);
            this.getSalesPersons();
            this.selectedMember = null;
          }
        })
        .catch(err => {
          console.error(err);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
    getRoleMembers(roleId) {
      this.roleMembers = [];
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/roleSystem/roleMembers?roleId=${roleId}`
      )
        .then(res => {
          this.roleMembers = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteRoleMember(member) {
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/roleSystem/delRoleSalesperson?salespersonId=${member.salespersonId}`,
        this.token
      )
        .then(res => {
          if (res.data) {
            this.getRoleMembers(this.editRole.id);
            this.getSalesPersons();
            this.snackbar = true;
            this.snackMessage = "Removed role from member successfully!";
          }
        })
        .catch(err => {
          console.error(err);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
    delButtonClick(item) {
      this.deleteRole.id = item.id;
      this.deleteRole.name = item.name;
      this.deleteRoleDialog = true;
    },
    doCreateRole() {
      try {
        this.createRole.dealerId = this.selectedDealer.id;
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/roleSystem/addRole`,
          this.createRole
        )
          .then(res => {
            if (res.data) {
              this.snackbar = true;
              this.snackMessage = "Role added Successfully!";
              this.getRoleList();
              this.createRole.name = "";
            }
          })
          .catch(err => {
            console.error(err);
            this.snackbar = true;
            this.snackMessage = "Error!";
          })
          .finally(() => {
            this.addRoleDialog = false;
          });
      } catch (e) {
        console.error(e);
      }
    },
    doDeleteRole() {
      const param = {
        roleId: this.deleteRole.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/roleSystem/delRole`,
        param,
        this.token
      )
        .then(res => {
          if (res.data) {
            this.snackbar = true;
            this.snackMessage = "Role deleted successfully!";
            this.getRoleList();
            this.editRoleDialog = false;
          }
        })
        .catch(err => {
          console.log(err);
          this.snackbar = true;
          this.snackMessage =
            "Cannot delete Role, Please reassign / remove existing members from this role.";
        })
        .finally(() => {
          this.deleteRoleDialog = false;
        });
    },
  },
};
